import { render, staticRenderFns } from "./admin-panel-edit-list.vue?vue&type=template&id=5d5af604&lang=pug&"
import script from "./admin-panel-edit-list.vue?vue&type=script&lang=js&"
export * from "./admin-panel-edit-list.vue?vue&type=script&lang=js&"
import style0 from "./admin-panel-edit-list.vue?vue&type=style&index=0&lang=sass&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AdminFileButton: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/admin-file-button.vue').default,RichTextarea: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/rich-textarea.vue').default,RestImg: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/rest-img.vue').default,TagInput: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/tag-input.vue').default})
