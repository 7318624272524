//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapActions } from 'vuex'
import '@mdi/font/css/materialdesignicons.css'

import {
	VIcon,
	VBtnToggle,
	VBtn,
	VTooltip,
	VSpacer,
	VTextField,
	VTextarea,
	VCol,
	VRow,
	VDialog,
	VContainer,
	VList,
	VListItem,
	VListItemTitle,
	VMenu,
	VFadeTransition,
	VProgressLinear,
	VProgressCircular,
} from 'vuetify/lib'

export default {

	components:
	{
		VIcon,
		VBtnToggle,
		VBtn,
		VTooltip,
		VSpacer,
		VTextField,
		VTextarea,
		VCol,
		VRow,
		VDialog,
		VContainer,
		VList,
		VListItem,
		VListItemTitle,
		VMenu,
		VFadeTransition,
		VProgressLinear,
		VProgressCircular,
	},

	props:
	{
		value:
		{
			type: Number,
			required: true,
		},

		loading:
		{
			type: Boolean,
			default: false,
		}
	},

	data()
	{
		return {
			selectedLocale: 0,
		}
	},

	computed:
	{
		...mapGetters(['getString', 'lang', 'langShort', 'allLanguages']),
	},

	watch:
	{
		selectedLocale(newVal)
		{
			this.$emit('input', newVal);
		},

		value(newVal)
		{
			if (newVal != this.selectedLocale)
				this.selectedLocale = newVal;
		}
	},

	beforeMount()
	{
		this.selectedLocale = this.value;
	},

	methods:
	{
		cloneListToOtherLocales()
		{
			this.$emit('clone-locale', this.selectedLocale)
		}
	}
}
