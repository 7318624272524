//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import Vue2Filters from 'vue2-filters'
import { SlickList, SlickItem } from 'vue-slicksort';
import { HandleDirective } from 'vue-slicksort';
// import SortableHandle from '~/components/sortable-handle';
// import SvgSymbol from './svg-symbol';
import RichTextarea from '~/components/rich-textarea';
import RestImg from '~/components/rest-img';
import AdminFileButton from '~/components/admin-file-button';
import TagInput from '~/components/tag-input'
import dayjs from 'dayjs'
import '@mdi/font/css/materialdesignicons.css'

import {
	// VApp,
	VIcon,
	VToolbar,
	VBtnToggle,
	VBtn,
	VTooltip,
	VSpacer,
	VCard,
	VCardTitle,
	VCardText,
	VExpansionPanels,
	VExpansionPanel,
	VExpansionPanelHeader,
	VExpansionPanelContent,
	VTextField,
	VTextarea,
	VCol,
	VRow,
	VDialog,
	VContainer,
	VList,
	VListItem,
	VListItemTitle,
	VDataTable,
	VMenu,
	VChip,
	VFadeTransition,
	VDatePicker,
	VSwitch,
	VProgressLinear,
	VProgressCircular,
	VAlert,
	VBottomSheet,
	VSheet,
	VCombobox,
} from 'vuetify/lib'
// import { Ripple } from 'vuetify/lib/directives'

const cloneDeep = require('lodash.clonedeep');

const fieldTypes = {
	'block':
	{
		'img': { type: 'image', },
		'mobile_img': { type: 'image', },
		// 'url': { type: 'url', },
		// 'url': { type: 'text', },
		'url': { type: 'url', },
		'content': { type: 'richtext', colSize: 12 },
		'taxonomies': { type: 'taxonomy' },
		'url_in_new_tab': { type: 'boolean' },
		'url_in_iframe': { type: 'boolean' },
		'has_taxonomy': { type: 'boolean' },
	},
	'quote':
	{
		'is_published': { type: 'boolean' },
		'img_path': { type: 'image', },
		'name': { type: 'text', },
		'job_title': { type: 'text', },
		'text': { type: 'longtext', colSize: 12 },
		'rating': { type: 'text', colSize: 2 },
		'date': { type: 'date', colSize: 4 },
		// 'content': { type: 'richtext', colSize: 12 },
	},
	'course':
	{
		'is_published': { type: 'boolean' },
		'thumbnail': { type: 'image', },
		'img': { type: 'image', },
		'title': { type: 'text', },
		'seats_remaining': { type: 'text', },
		'dates': { type: 'date', colSize: 4 },
		// 'content': { type: 'richtext', colSize: 12 },
	},
	'instructor':
	{
		'is_published': { type: 'boolean' },
		'img_path': { type: 'image', },
		'name': { type: 'text', },
		'job_title': { type: 'text', },
		'email': { type: 'text', },
		'phone': { type: 'text', },
	},
}

function capitalize(s, onlyFirstWord)
{
	if (typeof s !== 'string') return ''
	if (!onlyFirstWord)
		return s.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' ');
	else
		return s.charAt(0).toUpperCase() + s.slice(1)
}

export default {

	components:
	{
		VIcon,
		VToolbar,
		VBtnToggle,
		VBtn,
		VTooltip,
		VSpacer,
		VCard,
		VCardTitle,
		VCardText,
		VExpansionPanels,
		VExpansionPanel,
		VExpansionPanelHeader,
		VExpansionPanelContent,
		VTextField,
		VTextarea,
		VCol,
		VRow,
		VDialog,
		VContainer,
		VList,
		VListItem,
		VListItemTitle,
		VDataTable,
		VMenu,
		VChip,
		VFadeTransition,
		VDatePicker,
		VSwitch,
		VProgressLinear,
		VProgressCircular,
		VAlert,
		VBottomSheet,
		VSheet,
		VCombobox,

		SlickList, SlickItem,
		HandleDirective,
		RichTextarea,
		RestImg,
		TagInput,
		AdminFileButton,
		// SortableHandle, SvgSymbol
	},

	directives: { handle: HandleDirective },

	props:
	{
		title:
		{
			type: String,
			default: 'Edit'
		},

		isProcessing:
		{
			type: Boolean,
			default: false
		},

		// activeModel:
		// {
		// 	type: [String, Boolean, Number],
		// 	default: ''
		// },

		cmsList:
		{
			type: Object,
			required: true
		},
	},

	// mixins: [Vue2Filters.mixin],

	computed:
	{
		...mapGetters(['getString', 'lang', 'langShort', 'allLanguages']),
	},

	data()
	{
		return {
			isLoading: true,
			selectedLocaleIdx: 1, // default to greek
			showDeleteDialog: false,
			deleteDialogDisabled: false,
			deleteItem: null,
			deleteItemIndex: 0,
			fields: [],
			data: [],
			taxonomies: {},
			localeDataList: [],
			errorMessage: '',
		}
	},

	watch:
	{
		selectedLocaleIdx(newVal)
		{
			this.localeDataList = this.data[newVal] || [];
		},

		deleteItem(newVal)
		{
			this.showDeleteDialog = !!newVal;
		}
	},

	async mounted()
	{
		this.errorMessage = '';
		this.isLoading = true;

		this.taxonomies = new Array(this.allLanguages.length).fill(0).map(x => ({}) );

		this.data = /* cloneDeep */( this.cmsList.localeList || new Array(this.allLanguages.length).fill([]) );

		const taxonomies = {};

		//- this.fetchData()
		//- 	.then( () =>
			{
				var fields = [];
				for (var i = 0; i < this.cmsList.fields.length; i++)
				{
					const item = this.cmsList.fields[i];

					if (!item)	continue;

					// let values = [];
					// for (let i = 0; i < this.allLanguages.length; i++)
					// {
					// 	this.data[i]
					// }

					const itemType = this.getType(item);
					const propName = this.getName(item);
					const label = this.getLabel(item);

					if (itemType == 'taxonomy')
					{
						taxonomies[item.parentKey] = true;

						// make sure there is a taxonomy datasource with that name
						this.allLanguages.forEach( (x, idx) =>
						{
							this.taxonomies[idx][item.parentKey] = this.taxonomies[idx][item.parentKey] || []
						} )
					}

					fields.push({
						type: itemType,
						propName,
						parentKey: item.parentKey,
						key: i,
						// TODO: copy the values from the source
						// localeValue: new Array(this.allLanguages.length),
						colSize: this.getColSize(item),
						// label: this.$options.function.capitalize( name.replace(/_-/g, ' ') ),
						label,
					})
				}

				this.localeDataList = this.data[this.selectedLocaleIdx] || [];

				this.fields = fields;
				// this.alert.msg = "";
				// this.alert.type = "";
			}
		//- )

		consola.debug('taxonomies found: ', Object.keys(taxonomies));

		// load the taxonomy lists
		await Promise.all(   Object.keys(taxonomies).map( key => this.updateTaxonomy(key) )   )
			// .then( () =>
			// {
			// })
			.catch( error =>
			{
				consola.debug('admin-panel-edit-list error: ', error);
			})
			.finally( () =>
			{
				this.isLoading = false;
			})

	},

	methods:
	{
		updateTaxonomy(parentTax)
		{
			if (!parentTax)	return Promise.reject();

			const localeIdx = this.selectedLocaleIdx;

			consola.debug('updateTaxonomy: ', parentTax)
			return this.$apiGetTaxonomy({
											parent_key: parentTax,
											locale: '*',
											// locale: this.allLanguages[this.selectedLocaleIdx].lang_code
										})
				.then( (result) =>
				{
					// consola.debug('Object.keys(result): ', Object.keys(result) );
					// consola.debug('result.status: ', result.status );
					consola.debug('taxonomy['+parentTax+'] result: ', result);

					if (result.data)
					{
						let list = cloneDeep(this.taxonomies)
						for (let i = 0; i < this.allLanguages.length; i++)
						{
							// debugger;
							const locale = this.allLanguages[i];

							list[i][parentTax] = result.data.filter(x => x.locale_id == locale.id);
						}

						this.taxonomies = list;
						// consola.debug('updateTaxonomy : ', list)
						// debugger;
					}
					else
					{
						// this.taxonomies[localeIdx][parentTax] = []
					}
				})
				.catch( (error) =>
				{
					console.error('updateTaxonomy error: ', error)
				} )
		},

		getClasses(field)
		{
			var obj = { };
			if (this.cmsList.gridCell)	obj[this.cmsList.gridCell] = true;

			if (typeof field == 'string')
			{
				obj['grid-cell--'+field] = true;
			}
			else if (typeof field == 'object' && typeof field.propName !== 'undefined')
			{
				obj['grid-cell--'+field.propName] = true;
			}
			return obj;
		},

		getName(field)
		{
			if (typeof field == 'string')
			{
				return field;
			}
			else if (typeof field == 'object' && typeof field.name !== 'undefined')
			{
				return field.name;
			}
			return '';
		},

		getLabel(field)
		{
			if (typeof field == 'string')
			{
				return capitalize( (field || '').replace(/_/g, ' ') );
			}
			else if (typeof field == 'object' && typeof field.name !== 'undefined')
			{
				return capitalize( (field.label || field.name || '').replace(/_/g, ' ') )
				// return field.label || field.name || '';
			}
			return '';
		},

		getType(field)
		{
			// debugger;
			if (typeof field == 'string')
			{
				var table = fieldTypes[this.cmsList.type || this.cmsList.insertType] || null;
				if (!table || !table[field])	return 'text';
				return table[field].type || 'text';
			}
			else if (typeof field == 'object' )
			{
				if (field.type)
					return field.type;
				else
				{
					var table = fieldTypes[this.cmsList.type || this.cmsList.insertType] || null;
					if (!table || !table[field.name])	return 'text';
					return table[field.name].type || 'text';
				}
			}
			else
			{
				return 'text';
			}
		},

		getColSize(field)
		{
			if (typeof field == 'string')
			{
				var table = fieldTypes[this.cmsList.type || this.cmsList.insertType] || null;
				if (!table || !table[field])	return 4;
				return table[field].colSize || 4;
			}
			else if (typeof field == 'object' && typeof field.colSize !== 'undefined')
			{
				return field.colSize;
			}
			else
			{
				return 4;
			}
		},

		toDateStr(isoDate)
		{
			return dayjs(isoDate).format('YYYY-MM-DD');
			// return new Date(isoDate).toDateString();
		},

		//- fetchData()
		//- {
		//- 	switch (this.cmsList.extra_data_table)
		//- 	{
		//- 		case null:
		//- 		case '':
		//- 		case 'block':

		//- 			this.isLoading = true;
		//- 			// this.alert.msg = `loading data...`
		//- 			// this.alert.type = "info";
		//- 			return this.$apiGet('block',
		//- 						{
		//- 							locale: '*',
		//- 							id: this.cmsList.parentBlock.extra_data.map(x => x.block_id)
		//- 						})
		//- 			.then( resp =>
		//- 			{
		//- 				const data = resp.data || [];
		//- 				consola.debug('cmsList block data: ', data);

		//- 				let localeIdx = {};
		//- 				for (let i = 0; i < this.allLanguages.length; i++)
		//- 				{
		//- 					// localeIdx.push( this.allLanguages[i].id );
		//- 					localeIdx[this.allLanguages[i].id] = i;
		//- 				}

		//- 				this.data = {};
		//- 				for (let obj of data)
		//- 				{
		//- 					let list = this.data[ localeIdx[obj.locale_id] ] || [];
		//- 					list.push( obj );
		//- 					this.data[ localeIdx[obj.locale_id] ] = list;
		//- 				}

		//- 				consola.debug('cmsList locale data: ', this.data);

		//- 				this.isLoading = false;
		//- 				// this.alert.msg = "";
		//- 				// this.alert.type = "";
		//- 			})
		//- 			.catch( error =>
		//- 			{
		//- 				this.errorMessage = error;
		//- 				consola.debug('error getting result for block: ', error)
		//- 				// this.alert.msg = `error getting data for block`
		//- 				// this.alert.type = "error";
		//- 			});
		//- 			// break;

		//- 		default:
		//- 			return Promise.reject();
		//- 			// break;
		//- 	}
		//- },

		modify(item, field, event)
		{
			if (!item)	return;
			if (field && typeof event!='undefined')
			{
				// debugger
				if (typeof event == 'object' && Array.isArray(event)==false )
					item[field] = event.target.value;
				else
					item[field] = event;
			}
			this.$eventBus.$emit('modify:model', {obj: item});
		},

		onAddNew(index)
		{
			let apiEndPoint = null;
			let id_prop = null;
			// debugger
			switch (this.cmsList.extra_data_table)
			{
				case null:
				case '':
				case 'block':
				case 'blocks':
					apiEndPoint = 'block';
					id_prop = 'block_id';
					break;

				case 'quote':
				case 'quotes':
					apiEndPoint = 'quote';
					id_prop = 'quote_id';
					break;

				case 'instructor':
				case 'instructors':
					apiEndPoint = 'instructor';
					id_prop = 'instructor_id';
					break;

				case 'course':
				case 'courses':
					apiEndPoint = 'course';
					id_prop = 'course_id';
					break;

				case 'date':
				case 'dates':
					apiEndPoint = 'date';
					id_prop = 'id';
					break;

				case 'string':
				case 'strings':
					apiEndPoint = 'string';
					id_prop = 'string_id';
					break;

				case 'announcement':
				case 'announcements':
					apiEndPoint = 'announcement';
					id_prop = 'id';
					break;

				default:
					consola.debug('onAddNew unknown table: ', this.cmsList.parentBlock.extra_data_table)
					break;
			}

			if (apiEndPoint)
			{
				this.$emit('update:isProcessing', true)
				const type = this.cmsList.block_type || null;
				this.$apiPost(apiEndPoint, { block_type: type})
				.then( resp =>
				{
					consola.debug('onAddNew resp: ', resp);
					const createdList = resp.data;
					if ( Array.isArray(createdList) )
					{
						for ( let item of createdList )
						{
							if (apiEndPoint == 'block')
								item.taxonomies = item.taxonomies || [];

							for (let i = 0; i < this.allLanguages.length; i++)
							{
								if (item.locale_id == this.allLanguages[i].id)
								{
									this.$eventBus.$emit('modify:model', {obj: item});

									if (!this.data[i]) this.data[i] = [];
									if (this.data[i].length == 0)
									{
										this.data[i].push( item )
									}
									else
									{
										// append the newly created item in the locale list
										this.data[i].splice(index+1, 0, item)
									}
									break; // for languages loop
								}
							}
						}
					}
					this.localeDataList = this.data[this.selectedLocaleIdx] || [];
				} )
				.catch( error =>
				{
					consola.debug(`ERROR onAddNew ${this.cmsList.extra_data_table}:`, error)
				} )
				.finally( () =>
				{
					this.$emit('update:isProcessing', false)
				} )
			}
		},

		onInsert(index)
		{
			if ( !this.cmsList.insertType && !this.cmsList.block_type )
				return;

			this.$root.$off('selected:model-select');
			this.$root.$once('selected:model-select', (itemLocales) => {
				if (!itemLocales)	return;
				const items = cloneDeep(itemLocales);

				for ( let item of items )
				{
					for (let i = 0; i < this.allLanguages.length; i++)
					{
						if (item.locale_id == this.allLanguages[i].id)
						{
							if (!this.data[i]) this.data[i] = [];
							if (this.data[i].length == 0)
							{
								this.data[i].push( item )
							}
							else
							{
								// append the newly created item in the locale list
								this.data[i].splice(index+1, 0, item)
							}
							break; // for languages loop
						}
					}
				}

			});

			if (this.cmsList.type=='block' && this.cmsList.block_type)
				this.$root.$emit('open:model-select', this.cmsList.type, null,
											this.cmsList.block_type )
			else
				this.$root.$emit('open:model-select', this.cmsList.insertType )
		},

		onDeleteItem()
		{
			// debugger
			const item = this.deleteItem;
			const index = this.deleteItemIndex;
			consola.debug(`onDeleteItem(id: ${item.id}, index: ${index})`)

			// FIX: fix this

			if (!this.deleteItem)
				return;

			var extra_data = (this.localeDataList || []).map( x => x.id );
			if ( extra_data[this.deleteItemIndex] != this.deleteItem.id )
			{
				consola.debug(`warning: deleteItem[${this.deleteItem.id}] not found in list`)
			}
			else
			{
				extra_data.splice(this.deleteItemIndex, 1); // delete item

				// Delete item on ALL locales
				this.data.forEach( list => list.splice(this.deleteItemIndex, 1) );
			}

			let apiEndPoint = null;
			switch (this.cmsList.extra_data_table)
			{
				case null:
				case '':
				case 'block':
					apiEndPoint = 'block';
					break;

				case 'quote':
				case 'quotes':
					apiEndPoint = 'quote';
					break;

				case 'instructor':
				case 'instructors':
					apiEndPoint = 'instructor';
					break;

				case 'course':
				case 'courses':
					apiEndPoint = 'course';
					break;

				case 'date':
				case 'dates':
					apiEndPoint = 'date';
					break;

				case 'string':
				case 'strings':
					apiEndPoint = 'string';
					break;

				case 'announcement':
				case 'announcements':
					apiEndPoint = 'announcement';
					break;

				default:
					consola.debug('onDeleteItem unknown table: ', this.cmsList.extra_data_table)
					break;
			}

			if (apiEndPoint)
			{
				this.deleteDialogDisabled = true;

				// NOTE: this *always* acts on the extra_data array of a block
				//			it does *NOT* delete the actual items on the DB

				// update just the 'extra_data'
				this.$apiPut('block', {
					id: this.deleteItem.id,
					extra_data: JSON.stringify(extra_data),
				})
				.then( resp =>
				{
					this.deleteDialogDisabled = false;
					consola.debug('onDelete update extra_data resp: ', resp);
				} )
				.catch( error =>
				{
					consola.debug(`ERROR updating extra_data of ${this.cmsList.extra_data_table}:`, error)
				} )
				.finally( () =>
				{
					this.deleteItem = null;
				} )
			}
		},

		onClose()
		{
			consola.debug('onClose()')
			// TODO: onClose()

			// consola.debug('onClose: ', this.localeDataList);

			this.$root.$emit('update:cms-list-edit', {
												cmsList: this.cmsList,
												data: null,
												delete: [],
												create: [],
											});
			this.$emit('closeListEdit');
		},

		onSave()
		{
			consola.debug('onSave()')
			// TODO: onSave()

			// consola.debug('onSave: ', this.localeDataList);
			// return;

			this.cloneListToOtherLocales(['taxonomies', 'has_taxonomy'])
			// this.cloneListToOtherLocales(['has_taxonomy'])

			this.$root.$emit('update:cms-list-edit', {
												cmsList: this.cmsList,
												data: this.data,
												delete: [],
												create: [],
											});

			this.$emit('closeListEdit');

			//- switch (this.cmsList.parentBlock.extra_data_table)
			//- {
			//- 	case null:
			//- 	case '':
			//- 	case 'block':

			//- 		this.isProcessing = true;

			//- 		let list = [];
			//- 		for (let array of this.data)
			//- 		{
			//- 			list = list.concat(array);
			//- 		}

			//- 		// update just the 'extra_data'
			//- 		this.$apiPut('block', list)
			//- 		.then( resp =>
			//- 		{
			//- 			consola.debug('onSave update resp: ', resp);

			//- 			// save the extra_data JSON data
			//- 			let extra_data = this.localeDataList.map( x => x.id );
			//- 			return this.$apiPut('block', {
			//- 										id: this.localeDataList[0].id,
			//- 										extra_data: JSON.stringify(extra_data),
			//- 									})
			//- 		} )
			//- 		.then( () =>
			//- 		{
			//- 			// TODO: reload data in the page
			//- 			return;
			//- 		} )
			//- 		.catch( error =>
			//- 		{
			//- 			consola.debug(`ERROR saving ${this.cmsList.parentBlock.extra_data_table}:`, error)
			//- 		} )
			//- 		.finally( () =>
			//- 		{
			//- 			this.isProcessing = false;
			//- 		} )

			//- 		break;

			//- 	default:
			//- 		consola.debug('TODO: onSave ', this.cmsList.parentBlock.extra_data_table)
			//- 		break;
			//- }
		},

		cloneListToOtherLocales(propNames)
		{
			consola.debug('cloneListToOtherLocales', propNames)
			// debugger;

			let tax = this.fields.filter(x => x.type=="taxonomy");
			tax = tax.length ? tax[0] : null;
			const parentTax = tax ? tax.parentKey : null;

			if (propNames instanceof Event)	propNames = null;

			if (!propNames || !Array.isArray(propNames) || !propNames.length)
				propNames = Object
									.getOwnPropertyNames(this.localeDataList[0])
									.filter( x => x!="__ob__" && x!='id' );
			for (let k = 0; k < this.allLanguages.length; k++)
			{
				if (k == this.selectedLocaleIdx)	continue;

				for (let i = 0; i < this.localeDataList.length; i++)
				{
					const src = this.localeDataList[i];
					const dst = this.data[k][i];

					// debugger;
					for (let prop of propNames)
					{
						if (typeof src[prop] === 'undefined')	continue;
						if (prop == 'taxonomies' && parentTax)
						{
							dst[prop] = [];
							const taxList = this.taxonomies[k][parentTax];
							// consola.debug('taxList: ', taxList)
							for (let m = 0; m < src.taxonomies.length; m++)
							{
								// debugger;
								const srcTax = src.taxonomies[m];
								let foundTax = taxList.find(x => x.id==srcTax.taxonomy_id);
								// foundTax = foundTax.length ? foundTax[0] : null;
								if (foundTax)
								{
									foundTax = {...foundTax, taxonomy_id: srcTax.taxonomy_id};
									dst[prop].push(foundTax)
								}
							}
							// debugger;
						}
						else
							dst[prop] = cloneDeep(src[prop]);
					}
				}
			}
		},

		onSortEnd({ event, newIndex, oldIndex, collection })
		{
			consola.debug('onSortEnd: ', event)
			var list = [...this.data];
			for (let i = 0; i < this.allLanguages.length; i++)
			{
				// if (this.selectedLocaleIdx == i)	continue;

				let dragItem = list[i][oldIndex];
				let movedItem = list[i][newIndex];

				// list[i].splice(oldIndex, 1, movedItem);
				// list[i].splice(newIndex, 1, dragItem);
				list[i].splice(oldIndex, 1);
				list[i].splice(newIndex, 0, dragItem);
			}
			this.data = list || [];
			consola.debug('onSortEnd: data', list)
			this.$emit('input', event);
		},

		onInput(event)
		{
			consola.debug('input: ', event)
			// this.$emit('input', event);
			// this.$nextTick( ()=> this.sortFinished(event) )
			this.localeDataList = this.data[this.selectedLocaleIdx] || [];
		},

		onRemove(index)
		{
			consola.debug('onRemove: ', index)
			// let list = [...this.itemList]
			let list = [...this.itemList]
			list.splice(index, 1);
			this.$emit('input', list);
			// this.$nextTick( ()=> this.sortFinished(event) )
		},


	}
}
