//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { method } from 'lodash'
import {
	// VIcon,
	// VToolbar,
	// VToolbarTitle,
	// VToolbarItems,
	// VBtnToggle,
	VBtn,
	// VTooltip,
} from 'vuetify/lib'

export default {
	// props:
	// {
	// 	value: { required: true }
	// },

	components:
	{
		VBtn,
	},

	methods:
	{
		selectFromLibrary(event)
		{
			consola.debug('selectFromLibrary')
			event.preventDefault();
			event.stopPropagation();

			this.$eventBus.$emit('media:open', {url: 'media'})
			this.$eventBus.$once('media:selected', (obj)=>
			{
				if ( !obj )
					return;

				let uploadedPath = '';

				consola.debug('file path: ', obj.item && obj.item.name)
				if ( !obj.item )
					uploadedPath = '';
				else
				{
					uploadedPath = '/media/' + obj.item.name;
					this.$emit('input', uploadedPath);
				}
			})
		}
	}

}
