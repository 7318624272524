//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import LangSwitcher from '~/components/admin-ui-lang-switcher'
import '@mdi/font/css/materialdesignicons.css'

import {
	VApp,
	VIcon,
	VImg,
	VToolbar,
	VBtnToggle,
	VBtn,
	VTooltip,
	VSpacer,
	VCard,
	VCardTitle,
	VCardText,
	VExpansionPanels,
	VExpansionPanel,
	VExpansionPanelHeader,
	VExpansionPanelContent,
	VTextField,
	VTextarea,
	VCol,
	VRow,
	VForm,
	VDialog,
	VContainer,
	VList,
	VListItem,
	VListItemTitle,
	VDataTable,
	VMenu,
	VChip,
	VFadeTransition,
	VDatePicker,
	VSwitch,
	VProgressLinear,
	VProgressCircular,
	VAlert,
	VBottomSheet,
	VSheet,
	VCombobox,
} from 'vuetify/lib'
import { Ripple } from 'vuetify/lib/directives'

export default {
	props:
	{
		title:
		{
			type: String,
			default: 'Edit'
		},

		isProcessing:
		{
			type: Boolean,
			default: false
		},

		// activeModel:
		// {
		// 	type: [String, Boolean, Number],
		// 	default: ''
		// },

		selectedCollection:
		{
			type: Object,
			required: true
		},

		selectedCollectionItem:
		{
			type: [Object, Array],
			required: true
		},

		hideLangSwitcher:
		{
			type: Boolean,
			default: false,
		}

	},

	components:
	{
		LangSwitcher,
		VApp,
		VIcon,
		VImg,
		VToolbar,
		VBtnToggle,
		VBtn,
		VTooltip,
		VSpacer,
		VCard,
		VCardTitle,
		VCardText,
		VExpansionPanels,
		VExpansionPanel,
		VExpansionPanelHeader,
		VExpansionPanelContent,
		VTextField,
		VTextarea,
		VCol,
		VRow,
		VForm,
		VDialog,
		VContainer,
		VList,
		VListItem,
		VListItemTitle,
		VDataTable,
		VMenu,
		VChip,
		VFadeTransition,
		VDatePicker,
		VSwitch,
		VProgressLinear,
		VProgressCircular,
		VAlert,
		VBottomSheet,
		VSheet,
		VCombobox,
	},

	computed:
	{
		...mapGetters(['getString', 'lang', 'langShort', 'allLanguages']),
	},

	mounted()
	{
		const self = this;

		// Scroll to top fix
		setTimeout( () =>
		{
			if (self.$refs.body && self.$refs.body.scrollTo)
			{
				self.$refs.body.scrollTo(0, 0);
			}
		}, 100);
	},

	methods:
	{
		copy(event)
		{
			consola.debug('copy: ', event)
		},

		onSave()
		{
			if ( !this.$refs.form.validate() )
			{
				consola.debug('form is not valid');
				return;
			}
			this.$emit('saveCollectionItem');
		}
	}

	// data()
	// {
	// 	return {}
	// }
}
