//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
// import Vue2Filters from 'vue2-filters'
import { SlickList, SlickItem } from 'vue-slicksort';
import { HandleDirective } from 'vue-slicksort';
// import SortableHandle from '~/components/sortable-handle';
// import SvgSymbol from './svg-symbol';
import RichTextarea from '~/components/rich-textarea';
import RestImg from '~/components/rest-img';
import '@mdi/font/css/materialdesignicons.css'
// import TagInput from '~/components/tag-input'
// import dayjs from 'dayjs'

import {
	VIcon,
	VToolbar,
	VBtnToggle,
	VBtn,
	VTooltip,
	VSpacer,
	VCard,
	VCardTitle,
	VCardText,
	VExpansionPanels,
	VExpansionPanel,
	VExpansionPanelHeader,
	VExpansionPanelContent,
	VTextField,
	VTextarea,
	VCol,
	VRow,
	VDialog,
	VContainer,
	VList,
	VListItem,
	VListItemTitle,
	VDataTable,
	VMenu,
	VChip,
	VFadeTransition,
	VDatePicker,
	VSwitch,
	VProgressLinear,
	VProgressCircular,
	VAlert,
	VBottomSheet,
	VSheet,
	VCombobox,
} from 'vuetify/lib'
// import { Ripple } from 'vuetify/lib/directives'

const cloneDeep = require('lodash.clonedeep');

const isFunction = value => value && (Object.prototype.toString.call(value) === "[object Function]" || "function" === typeof value || value instanceof Function);

const fieldTypes = Object.freeze({
	'logo':
	{
		// 'img': { type: 'image', },
		// 'mobile_img': { type: 'image', },
		// 'url': { type: 'url', },
		'label': { type: 'longtext', },
		'url': { type: 'text', },
		// 'content': { type: 'richtext', colSize: 12 },
		// 'taxonomies': { type: 'taxonomy' }
	},
	'spacer':
	{

	},
	'label':
	{
		'label': { type: 'longtext', },
		'mobileLabel': { type: 'longtext', },
	},
	'footer-item':
	{
		'label': { type: 'longtext', },
		'mobileLabel': { type: 'longtext', },
		'is_row': { type: 'boolean' },
		'list': { type: 'list' },
	},
	'link':
	{
		'label': { type: 'longtext', },
		'mobileLabel': { type: 'longtext', },
		'url': { type: 'text', },
		// 'new_tab': { type: 'boolean' },
		// 'is_form': { type: 'boolean' },
		'analytics_event': { type: 'text' },
		'is_underlined': { type: 'boolean' },
		'has_icon': { type: 'boolean' },
		'open_in_new_tab': { type: 'boolean' },
		'is_external': { type: 'boolean' },
	},
	'modal-link':
	{
		'label': { type: 'longtext', },
		'mobileLabel': { type: 'longtext', },
		'url': { type: 'text', },
		// 'new_tab': { type: 'boolean' },
		// 'is_form': { type: 'boolean' },
		'analytics_event': { type: 'text' },
		// 'open_in_new_tab': { type: 'boolean' },
		// 'is_external': { type: 'boolean' },
	},
	'form-link':
	{
		'label': { type: 'longtext', },
		'mobileLabel': { type: 'longtext', },
		'url': { type: 'text', },
		// 'new_tab': { type: 'boolean' },
		// 'is_form': { type: 'boolean' },
		'analytics_event': { type: 'text' },
	},

});

const actions = Object.freeze([
	{ label: '+Logo', insertObject: { type: 'logo', label: '', url: '' } },
	{ label: '+Spacer', insertObject: { type: 'spacer' } },
	{ label: '+Label', insertObject: { type: 'label', label: '', mobileLabel: '' } },
	{ label: '+Footer Item', insertObject: { type: 'footer-item', label: '', mobileLabel: '', is_row: false,  list: [{ type: 'link', label: '', mobileLabel: '', url: '', analytics_event: '', is_underlined: false, has_icon: false }] } },
	{ label: '+Link', insertObject: { type: 'link', label: '', mobileLabel: '', url: '', analytics_event: '', is_underlined: false, has_icon: false } },
	{ label: '+Modal Link', insertObject: { type: 'modal-link', label: '', mobileLabel: '', url: '', analytics_event: '' } },
	{ label: '+Form Link', insertObject: { type: 'form-link', label: '', mobileLabel: '', url: '', analytics_event: '' } },
]);

const secondaryFieldTypes = Object.freeze({

	'spacer':
	{

	},
	'label':
	{
		'label': { type: 'longtext', },
		'mobileLabel': { type: 'longtext', },
	},
	'link':
	{
		'label': { type: 'longtext', },
		'mobileLabel': { type: 'longtext', },
		'url': { type: 'text', },
		// 'new_tab': { type: 'boolean' },
		// 'is_form': { type: 'boolean' },
		'analytics_event': { type: 'text' },
		'is_underlined': { type: 'boolean' },
		'has_icon': { type: 'boolean' },
		'open_in_new_tab': { type: 'boolean' },
		'is_external': { type: 'boolean' },
	},
	'modal-link':
	{
		'label': { type: 'longtext', },
		'mobileLabel': { type: 'longtext', },
		'url': { type: 'text', },
		// 'new_tab': { type: 'boolean' },
		// 'is_form': { type: 'boolean' },
		'analytics_event': { type: 'text' },
	},
	'image-link':
	{
		'img': { type: 'image', },
		'url': { type: 'text', },
		'analytics_event': { type: 'text' },
		'open_in_new_tab': { type: 'boolean' },
		'is_external': { type: 'boolean' },
	},
	'form-link':
	{
		'label': { type: 'longtext', },
		'mobileLabel': { type: 'longtext', },
		'url': { type: 'text', },
		// 'new_tab': { type: 'boolean' },
		// 'is_form': { type: 'boolean' },
		'analytics_event': { type: 'text' },
	},

});

const secondaryActions = Object.freeze([
	{ label: '+Spacer', insertObject: { type: 'spacer' } },
	{ label: '+Label', insertObject: { type: 'label', label: '', mobileLabel: '' } },
	{ label: '+Link', insertObject: { type: 'link', label: '', mobileLabel: '', url: '', analytics_event: '', is_underlined: false, has_icon: false } },
	{ label: '+Modal Link', insertObject: { type: 'modal-link', label: '', mobileLabel: '', url: '', analytics_event: '' } },
	{ label: '+Form Link', insertObject: { type: 'form-link', label: '', mobileLabel: '', url: '', analytics_event: '' } },
	{ label: '+Image Link', insertObject: { type: 'image-link', img: '', url: '', analytics_event: '' } },
]);

function capitalize(s, onlyFirstWord)
{
	if (typeof s !== 'string') return ''
	if (!onlyFirstWord)
		return s.split(' ').map(x => x.charAt(0).toUpperCase() + x.slice(1)).join(' ');
	else
		return s.charAt(0).toUpperCase() + s.slice(1)
}

export default {

	components:
	{
		VIcon,
		VToolbar,
		VBtnToggle,
		VBtn,
		VTooltip,
		VSpacer,
		VCard,
		VCardTitle,
		VCardText,
		VExpansionPanels,
		VExpansionPanel,
		VExpansionPanelHeader,
		VExpansionPanelContent,
		VTextField,
		VTextarea,
		VCol,
		VRow,
		VDialog,
		VContainer,
		VList,
		VListItem,
		VListItemTitle,
		VDataTable,
		VMenu,
		VChip,
		VFadeTransition,
		VDatePicker,
		VSwitch,
		VProgressLinear,
		VProgressCircular,
		VAlert,
		VBottomSheet,
		VSheet,
		VCombobox,

		SlickList, SlickItem,
		HandleDirective,
		RichTextarea,
		RestImg,
		// TagInput,
		// SortableHandle, SvgSymbol
	},

	directives: { handle: HandleDirective },

	props:
	{
		title:
		{
			type: String,
			default: 'Edit'
		},

		isProcessing:
		{
			type: Boolean,
			default: false
		},

		// activeModel:
		// {
		// 	type: [String, Boolean, Number],
		// 	default: ''
		// },

		// each item in the array is of the form:
		//	{ label: <string>, action?: <function>, insertObject?: <object> }
		// actions:
		// {
		// 	type: Array,
		// 	default: () => ([])
		// },

		collection:
		{
			type: Object,
			required: true,
		},

		localeList:
		{
			type: Array,
			required: true,
		}

		// cmsList:
		// {
		// 	type: Object,
		// 	required: true
		// },
	},

	// mixins: [Vue2Filters.mixin],

	computed:
	{
		...mapGetters(['getString', 'lang', 'langShort', 'allLanguages']),

		localeData()
		{
			return this.data && this.data[this.selectedLocale] || {};
		}
	},

	data()
	{
		return {
			isLoading: true,
			selectedLocale: 0,
			showDeleteDialog: false,
			deleteDialogDisabled: false,
			deleteItem: null,
			deleteItemIndex: 0,
			fields: fieldTypes,
			secondaryFields: secondaryFieldTypes,
			actions,
			secondaryActions,
			data: [],
			localeDataList: [],
			sortingList: [],
			errorMessage: '',
		}
	},

	watch:
	{
		selectedLocale(newVal)
		{
			this.localeDataList = this.data[newVal].json;
			if ( !Array.isArray(this.localeDataList) )
			{
				consola.debug('ERROR: localeDataList has typeof ', typeof this.localeDataList)
			}
			else
			{
				this.localeDataList.forEach((val, idx) => val._key = val._key || (newVal+'_'+(idx+1)))
			}
		},

		deleteItem(newVal)
		{
			this.showDeleteDialog = !!newVal;
		}
	},

	async mounted()
	{
		this.errorMessage = '';
		this.isLoading = true;

		this.data = /* cloneDeep */( this.localeList )

		this.localeDataList = this.data[this.selectedLocale].json;
		this.localeDataList.forEach((val, idx) => val._key = val._key || (this.selectedLocale+'_'+(idx+1)))
		this.isLoading = false;
	},

	methods:
	{

		// getClasses(field)
		// {
		// 	var obj = { };
		// 	if (this.cmsList.gridCell)	obj[this.cmsList.gridCell] = true;

		// 	if (typeof field == 'string')
		// 	{
		// 		obj['grid-cell--'+field] = true;
		// 	}
		// 	else if (typeof field == 'object' && typeof field.propName !== 'undefined')
		// 	{
		// 		obj['grid-cell--'+field.propName] = true;
		// 	}
		// 	return obj;
		// },

		getName(field)
		{
			if (typeof field == 'string')
			{
				return field;
			}
			else if (typeof field == 'object' && typeof field.name !== 'undefined')
			{
				return field.name;
			}
			return '';
		},

		getLabel(field)
		{
			if (typeof field == 'string')
			{
				return capitalize( (field || '').replace(/_/g, ' ') );
			}
			else if (typeof field == 'object' && typeof field.name !== 'undefined')
			{
				return capitalize( (field.label || field.name || '').replace(/_/g, ' ') )
				// return field.label || field.name || '';
			}
			return '';
		},

		getType(field)
		{
			// if (typeof field == 'string')
			// {
			// 	var table = fieldTypes[this.cmsList.type] || null;
			// 	if (!table || !table[field])	return 'text';
			// 	return table[field].type || 'text';
			// }
			// else
			if (typeof field == 'object' )
			{
				if (field.type)
					return field.type;
			}

			return 'text';
		},

		// getColSize(field)
		// {
		// 	if (typeof field == 'string')
		// 	{
		// 		var table = fieldTypes[this.cmsList.type] || null;
		// 		if (!table || !table[field])	return 4;
		// 		return table[field].colSize || 4;
		// 	}
		// 	else if (typeof field == 'object' && typeof field.colSize !== 'undefined')
		// 	{
		// 		return field.colSize;
		// 	}
		// 	else
		// 	{
		// 		return 4;
		// 	}
		// },

		// toDateStr(isoDate)
		// {
		// 	return dayjs(isoDate).format('YYYY-MM-DD');
		// 	// return new Date(isoDate).toDateString();
		// },


		modify(item, field, event)
		{
			// debugger;
			if (!item)	return;
			if (field && typeof event!='undefined')
			{
				// debugger
				// consola.debug('modify: ', event)
				if (typeof event == 'object' && Array.isArray(event)==false )
					item[field] = event.target.value;
				else
					item[field] = event;
			}
			this.$eventBus.$emit('modify:model', {obj: this.localeData});
		},

		onAction(list, elemIndex, cmd)
		{
			if (!cmd)
			{
				return;
			}

			consola.debug('onAction: ', cmd.label)

			let obj = null;

			if (cmd.action && isFunction(cmd.action))
			{
				// obj = cmd.action(elemIndex, this.localeDataList[elemIndex], this.collection)
				obj = cmd.action(elemIndex, list[elemIndex], this.collection)
			}
			else if (cmd.insertObject)
			{
				obj = cloneDeep(cmd.insertObject)
			}

			do
			{
				obj._key = this.selectedLocale+'_' + Math.floor(Math.random()*9999);
			}
			// while (this.localeDataList.filter(x => x._key==obj._key).length > 0)
			while (list.filter(x => x._key==obj._key).length > 0)

			if (obj)
			{
				// this.$eventBus.$emit('modify:model', {obj: item});

				// append the newly created item in the locale list
				// this.localeDataList.splice(elemIndex+1, 0, obj)
				list.splice(elemIndex+1, 0, obj)
			}

		},


		onDeleteItem(list)
		{
			// debugger
			const item = this.deleteItem;
			const index = this.deleteItemIndex;
			consola.debug(`onDeleteItem(id: ${item.id}, index: ${index})`)

			// this.localeDataList.splice(index, 1)
			list.splice(index, 1)
		},

		onClose()
		{
			consola.debug('onClose()')
			// TODO: onClose()

			// consola.debug('onClose: ', this.localeDataList);

			this.$emit('close');
		},

		onSave()
		{
			consola.debug('onSave()')
			// TODO: onSave()

			// consola.debug('onSave: ', this.localeDataList);
			// return;

			// this.$root.$emit('update:cms-list-edit', {
			// 									cmsList: this.cmsList,
			// 									data: this.data,
			// 									delete: [],
			// 									create: [],
			// 								});

			this.$emit('save');
			this.$emit('close');

		},

		cloneListToOtherLocales()
		{
			// TODO: cloneListToOtherLocales
			consola.debug('cloneListToOtherLocales()')

			const propNames = Object
								.getOwnPropertyNames(this.localeData)
								.filter( x => x!="__ob__" && x!='id' && x!='_key' && x!='locale_id' );
			for (let k = 0; k < this.allLanguages.length; k++)
			{
				if (k == this.selectedLocale)	continue;

				const src = this.localeData;
				const dst = this.data[k];

				// debugger;
				for (let prop of propNames)
				{
					if (typeof src[prop] === 'undefined')	continue;
					dst[prop] = cloneDeep(src[prop]);
				}

			}
		},

		onSortStart(list)
		{
			this.sortingList = list
		},

		onSortEnd({ event, newIndex, oldIndex, collection })
		{
			consola.debug(`onSortEnd: new[${newIndex}] old[${oldIndex}]`)

			if ( !this.sortingList )
			{
				consola.debug('error: no sortingList has been defined');
				return;
			}
			if (oldIndex==newIndex)	return;

			// var list = [ ...this.localeDataList ];
			// for (let i = 0; i < this.allLanguages.length; i++)
			{
				// if (this.selectedLocale == i)	continue;

				let dragItem = this.sortingList[oldIndex];
				let movedItem = this.sortingList[newIndex];

				// list[i].splice(oldIndex, 1, movedItem);
				// list[i].splice(newIndex, 1, dragItem);
				this.sortingList.splice(oldIndex, 1);
				this.sortingList.splice(newIndex, 0, dragItem);
			}
			// this.data[this.selectedLocale].json = list;
			consola.debug('onSortEnd: data', this.sortingList)
			this.$emit('input', event);
		},

		onInput(event)
		{
			consola.debug('input: ', event)
			// // this.$emit('input', event);
			// // this.$nextTick( ()=> this.sortFinished(event) )
			// this.localeDataList = this.data[this.selectedLocale].json;
		},

		onRemove(index)
		{
			consola.debug('onRemove: ', index)
			// let list = [...this.itemList]
			let list = [...this.itemList]
			list.splice(index, 1);
			this.$emit('input', list);
			// this.$nextTick( ()=> this.sortFinished(event) )
		},


	}
}
