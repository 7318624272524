//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'
import '@mdi/font/css/materialdesignicons.css'
import loadImage from '~/lib/image-promise';
import LangSwitcher from '~/components/admin-ui-lang-switcher';
// const loadImage = require('image-promise');
const cloneDeep = require('lodash.clonedeep');
// const slugify = require('slugify');

import {
	VIcon,
	VImg,
	VToolbar,
	VToolbarTitle,
	VToolbarItems,
	VBtnToggle,
	VBtn,
	VTooltip,
	VSpacer,
	VCard,
	VCardTitle,
	VCardText,
	VExpansionPanels,
	VExpansionPanel,
	VExpansionPanelHeader,
	VExpansionPanelContent,
	VTextField,
	VTextarea,
	VCol,
	VRow,
	VDialog,
	VContainer,
	VItem,
	VItemGroup,
	VList,
	VListItem,
	VListItemTitle,
	VDataTable,
	VMenu,
	VChip,
	VFadeTransition,
	VDatePicker,
	VSwitch,
	VProgressLinear,
	VProgressCircular,
	VAlert,
	VBottomSheet,
	VSheet,
	VCombobox,
} from 'vuetify/lib'
// import { Ripple } from 'vuetify/lib/directives'

const isDev = process.env.NODE_ENV !== 'production';
// const MEDIA_HOST = process.env.MEDIA_HOST || '';
// consola.debug('process.env.MEDIA_HOST: ', process.env.MEDIA_HOST)
// const MEDIA_HOST = isDev ? 'https://eltran.humble.gr' : '' ;
// consola.debug('isDev: ', process.env.NODE_ENV)

function isValidDate(dateObject)
{
	return new Date(dateObject).toString() !== 'Invalid Date';
}


export default {

	components:
	{
		VIcon,
		VImg,
		VToolbar,
		VToolbarTitle,
		VToolbarItems,
		VBtnToggle,
		VBtn,
		VTooltip,
		VSpacer,
		VCard,
		VCardTitle,
		VCardText,
		VExpansionPanels,
		VExpansionPanel,
		VExpansionPanelHeader,
		VExpansionPanelContent,
		VTextField,
		VTextarea,
		VCol,
		VRow,
		VDialog,
		VContainer,
		VItem,
		VItemGroup,
		VList,
		VListItem,
		VListItemTitle,
		VDataTable,
		VMenu,
		VChip,
		VFadeTransition,
		VDatePicker,
		VSwitch,
		VProgressLinear,
		VProgressCircular,
		VAlert,
		VBottomSheet,
		VSheet,
		VCombobox,

		'lang-switcher': LangSwitcher
	},

	props:
	{

	},

	data()
	{
		return {
			isOpen: false,
			isLoading: true,
			isUploading: false,
			uploadPromise: null,
			uploadProgress: 0,
			showSelectButton: true,
			sortingModel: 0,
			sortDescendingModel: 1,
			selectedItem: null,
			selectedKey: 0,
			prevSelectedItem: null,
			// sortAscending: true,
			pictures: [],
			selected: null,
			currentLang: 0, // this is the index to the locale array, not the locale_id
			showImgProps: false,
			loadingImgProps: true,
			imgLocaleProps:
			[
				{
					name: '',
					description: '',
					type_info: '',
					width: null,
					height: null,
				},
			],
			// imgWidth: null,
			// imgHeight: null,
			watchImgProps: false,
			imgSaveQueue: {},
			imgSaveQueueTimer: null,
		};
	},

	watch:
	{
		isOpen(newVal)
		{
			if (newVal)
			{
				this.isLoading = true;
				this.fecthData()
					.then( (success)=>
					{
						if (success)
						{
							this.isLoading = false
						}
					}
					);
			}
			else
			{

			}
		},

		// selected(newVal, prevVal)
		// {
		// 	consola.debug('selected: ', newVal);
		// 	this.watchImgProps = false;
		// 	// setTimeout(() =>
		// 	// {
		// 	// 	this.watchImgProps = true;
		// 	// }, 0);
		// 	if (typeof newVal !== 'undefined' && newVal !== null)
		// 	{
		// 		const selItem = this.filteredPictures[newVal];

		// 		if (typeof prevVal !== 'undefined' && prevVal !== null)
		// 		{
		// 			this.saveSeoImgData(this.prevSelectedItem._idx);
		// 			this.postSeoImgData();
		// 		}
		// 		this.fetchSeoImgData(selItem);
		// 		this.fetchImgDimensions(selItem);
		// 		this.showImgProps = true;

		// 	}
		// 	else
		// 		this.showImgProps = false;


		// 	// NOTE: there is a bug in Chrome or Vue that causes the 1st button
		// 	// 		 to become shaded when a new picture is selected
		// 	setTimeout( () =>
		// 	{
		// 		// consola.debug('setting button to ', this.currentLang)
		// 		this.$refs.langSwitcher.setButton(this.currentLang)
		// 	}, 500)
		// },

		currentLang(newVal)
		{
			consola.debug('currentLang: ', newVal)
			// temporarily disable imgProp watching
			// to prevent unnecessary saving data to server
			this.watchImgProps = false;
			setTimeout(() =>
			{
				this.watchImgProps = true;
			}, 0);
		},

	},

	computed:
	{
		...mapGetters(['getString', 'lang', 'langShort', 'allLanguages']),

		activeLanguages()
		{
			return this.allLanguages.filter(x => x.is_active);
		},

		filteredPictures()
		{
			if (!this.pictures)	return [];
			return this.pictures.filter( x => (!!x && x.extension!='webp'));
			// return this.pictures;//.filter( x => (!!x));
			// return this.pictures.filter(x => this.isPic(x));
		},


		imgProps()
		{
			let ret = this.imgLocaleProps[this.currentLang] || { width: null, height: null };
			this.imgLocaleProps.forEach(x => { ret.width = x.width || ret.width; ret.height = x.height || ret.height;} )
			return ret;
		},


		imgDim()
		{
			if (this.imgProps.width)
				return `${this.imgProps.width}px × ${this.imgProps.height}px`
				// return `${this.imgProps.width}px ✕ ${this.imgProps.height}px`
			else
				return '';
		}
	},

	beforeMount()
	{
		this.initImgProps();
	},

	mounted()
	{

	},

	beforeDestroy()
	{
		if (this.imgSaveQueueTimer)
		{
			clearTimeout(this.imgSaveQueueTimer)
			this.imgSaveQueueTimer = null;
			this.postSeoImgData();
		}
	},

	methods:
	{
		initImgProps()
		{
			var list = [];
			for (let lang of this.allLanguages)
			{
				list.push(
					{
						type: "ImageObject",
						locale_id: lang.id,
						name: '',
						description: '',
						type_info: '',
						width: null,
						height: null,
					}
				);
			}

			this.imgLocaleProps = list;
		},

		onSelect(item)
		{
			// WARNING: do NOT use the native 'v-item-group' selection
			//			because it does not update properly on sort

			consola.debug(`onSelect`, item);
			// debugger;
			if (item == this.selectedItem)
				this.selectedItem = null;
			else
				this.selectedItem = item;



			this.watchImgProps = false;
			// setTimeout(() =>
			// {
			// 	this.watchImgProps = true;
			// }, 0);
			if (typeof this.selectedItem !== 'undefined' && this.selectedItem !== null)
			{
				const selItem = this.selectedItem;

				if (typeof this.prevSelectedItem !== 'undefined' && this.prevSelectedItem !== null)
				{
					this.saveSeoImgData(this.prevSelectedItem._idx);
					this.postSeoImgData();
				}
				this.fetchSeoImgData(selItem);
				this.fetchImgDimensions(selItem);
				this.showImgProps = true;

			}
			else
				this.showImgProps = false;


			// NOTE: there is a bug in Chrome or Vue that causes the 1st button
			// 		 to become shaded when a new picture is selected
			setTimeout( () =>
			{
				// consola.debug('setting button to ', this.currentLang)
				this.$refs.langSwitcher.setButton(this.currentLang)
			}, 500)
		},

		fecthData()
		{
			this.selected = null;
			this.showImgProps = false
			return this.$apiGet('media')
					.then( resp =>
					{
						consola.debug('GET media resp: ', resp);
						const data = resp.data;

						if (data)
						{
							let counter = 1;
							this.pictures = data.map(x => {
								x.extension = x.name.split('.').slice(-1).join('').toLowerCase();
								x.type = 'unknown';
								x._idx = counter++;
								if (this.isPic(x))
									x.type = 'pic';
								else
									x.type = 'file';

								return x;
							});

							this.sort();
							return true;
						}

						return false;
					})
					.catch( error =>
					{
						consola.debug('Error retreiving media: ', error);
						this.$emit('alert', {msg: 'Could not retrieve Media', type: 'error' })
						return false;
					});
		},

		open(showSelectButton = true)
		{
			if (this.isOpen)
			{
				consola.debug('media-selector already open, ignoring...');
				return;
			}
			this.isOpen = true;
			this.selected = null;
			this.showSelectButton = showSelectButton;
		},

		close()
		{
			this.saveSeoImgData();
			this.postSeoImgData();
			this.isOpen = false;
			this.$eventBus.$emit('media:selected');
			this.selected = null;
		},

		// fetchImgDimensions(idx)
		fetchImgDimensions(item)
		{
			this.imgProps.width = 0;
			this.imgProps.height = 0;
			// if ( !this.filteredPictures[idx] )	return;
			if ( !item )	return;

			// const url = '/media/' + this.filteredPictures[idx].name
			const url = '/media/' + item.name

			const self = this;
			// loadImage(url, { crossorigin: 'anonymous' })
			loadImage(url)
			.then( img =>
			{
				self.imgProps.width = img.naturalWidth;
				self.imgProps.height = img.naturalHeight;

				if (typeof self.selected !=='undefined' )
				{
					self.imgSaveQueue[item._idx] = self.imgSaveQueue[item._idx] || cloneDeep(self.imgLocaleProps);
					self.imgSaveQueue[item._idx].forEach(x =>
													{
														x.width = self.imgProps.width;
														x.height = self.imgProps.height;
													})
					self.imgLocaleProps.forEach(x =>
												{
													x.width = self.imgProps.width;
													x.height = self.imgProps.height;
												})
					// this.imgSaveQueue[item._idx][this.currentLang].width = this.imgProps.width;
					// this.imgSaveQueue[item._idx][this.currentLang].height = this.imgProps.height;
				};

				consola.debug(`img dim [${self.imgProps.width}, ${self.imgProps.height}]`)
			})
			.catch( error =>
			{
				consola.debug(`error loading [${url}] `, error)
			});
		},

		// fetchSeoImgData(idx)
		fetchSeoImgData(item)
		{
			// if ( !this.filteredPictures[idx] )	return;
			if (!item)	return;

			this.prevSelectedItem = item;

			this.loadingImgProps = true;
			this.watchImgProps = false;
			this.imgProps.name = ''
			this.imgProps.description = ''
			this.imgProps.type_info = ''
			// this.watchImgProps = true;

			// consola.debug(`fetching seo data for [${this.filteredPictures[idx].name}]`);
			consola.debug(`fetching seo data for [${item.name}]`);

			// clear the values
			this.initImgProps();


			this.$apiGet('seo', {
				// owner_url: '/media/' + this.filteredPictures[idx].name
				owner_url: '/media/' + item.name
			})
			.then( resp =>
			{
				consola.debug('fetchSeoImgData: ', resp)

				if ( resp.data && Array.isArray(resp.data[0]) )
				{
					this.watchImgProps = false;

					for (let i = 0; i < resp.data[0].length; i++)
					{
						const item = resp.data[0][i];
						if (!item.data)
						{
							consola.debug(`fetchSeoImgData warning: [${item.name}] has not data`);
							continue;
						}

						const json = JSON.parse( item.data );
						if (!json)	continue;

						let prop = this.imgLocaleProps.find(x => x.locale_id==item.locale_id);
						if (!prop)	continue;

						prop.name = json.name || '';
						prop.description = json.description || '';
						prop.type_info = json.type_info || '';
						prop.width = json.width || prop.width;
						prop.height = json.height || prop.height;
						consola.debug(`seo img dim [${prop.width}, ${prop.height}]`)
					}
				}

				this.loadingImgProps = false;
			})
			.catch( error =>
			{
				consola.debug('Error: ', error)
				this.watchImgProps = false;
				this.imgProps.name = ''
				this.imgProps.description = ''
				this.imgProps.type_info = ''
				this.imgProps.width = 0
				this.imgProps.height = 0
				this.loadingImgProps = false;
			})
			.finally( () =>
			{
				setTimeout(() => {
					this.watchImgProps = true;
				}, 0);
			})
		},

		cloneImgProps(event)
		{
			consola.debug('cloneImgProps: ', event)
			// event.stopPropagation();
			event.preventDefault();
			let list = [];
			for (let i = 0; i < this.imgLocaleProps.length; i++)
			{
				if (i == this.currentLang)
					list[i] = cloneDeep(this.imgLocaleProps[i]);
				else
					list[i] = cloneDeep(this.imgLocaleProps[this.currentLang]);

				list[i].locale_id = this.imgLocaleProps[i].locale_id;
			}
			this.imgLocaleProps = list;
		},

		// saveSeoImgData(item)
		saveSeoImgData(picIndex)
		{
			// if ( !this.watchImgProps )	return;

			// picIndex = picIndex || this.selected;
			if (typeof picIndex == 'undefined')	return;
			if (this.imgSaveQueueTimer)
			{
				clearTimeout(this.imgSaveQueueTimer);
				this.imgSaveQueueTimer = null;
			}

			this.imgSaveQueue[picIndex] = cloneDeep(this.imgLocaleProps);

			consola.debug(`saveSeoImgData[${picIndex}] `, this.imgSaveQueue[picIndex]);

			this.imgSaveQueueTimer = setTimeout( () => this.postSeoImgData(), 1000 );
		},

		postSeoImgData()
		{
			if (this.imgSaveQueueTimer)
			{
				clearTimeout(this.imgSaveQueueTimer)
				this.imgSaveQueueTimer = null;
			}

			const keys = Object.keys(this.imgSaveQueue).map( x => parseInt(x) );
			let list = [];
			for (let key of keys)
			{
				// if ( Number.isNaN( parseInt(key) ) )	continue;
				let item = this.filteredPictures.find(x => x._idx==key)
				if (!item)
					continue;

				// item = item[0];

				for (let i = 0; i < this.allLanguages.length; i++)
				{
					const lang = this.allLanguages[i];

					list.push({
						type: 'ImageObject',
						locale_id: lang.id,
						owner_url: '/media/' + item.name,
						data:
						{
							name: this.imgSaveQueue[key][i].name,
							description: this.imgSaveQueue[key][i].description,
							type_info: this.imgSaveQueue[key][i].type_info,
							contentUrl: '/media/' + item.name,
							contentSize: Math.round(item.size / 1000) + 'kb',
							width: this.imgSaveQueue[key][i].width,
							height: this.imgSaveQueue[key][i].height,
						}
					})
				}	// for lang end
			}	// for keys end

			this.imgSaveQueue = {};

			if (list.length == 0)	return;
			consola.debug('saving seo: ', list);
			this.$apiPut('seo', list)
			.then( resp =>
			{
				consola.debug('postSeoImgData: ', resp)
			})
			.catch( error =>
			{
				consola.debug('postSeoImgData error: ', error)
			})
		},

		isPic(item)
		{
			const permited = ['png', 'jpg', 'jpeg', 'gif', 'svg'];
			if ( !this.getName(item) ) return false;
			let ext = item.name.split('.').pop().toLowerCase();
			// return permited.filter(x => ext == x).length > 0;
			return permited.filter(x => item.extension == x).length > 0;
		},

		isFile(item)
		{
			const imgTypes = ['png', 'jpg', 'jpeg', 'gif', 'svg'];
			// const permited = ['doc', 'docx', 'pdf', 'ppt', 'xls', 'xlsx', 'zip'];
			if ( !this.getName(item) ) return false;
			// let ext = item.name.split('.').pop().toLowerCase();
			return !imgTypes.includes((item.extension || '').toLowerCase())
			// return imgTypes.filter(x => item.extension == x).length > 0;
		},

		isWebp(item)
		{
			return item.name.split('.').pop().toLowerCase() == 'webp';
		},

		getImgUrl(item)
		{
			// return (MEDIA_HOST || '') + '/media/' + item.name
			return '/media/' + item.name
		},

		getName(item)
		{
			let parts = item.name.split('_');
			if (parts.length > 1)
			{
				if (isValidDate( parts[0].split('T')[0] ))
					return parts.slice(1).join('_');
				else
					return parts.join('_');
			}

			return item.name;
			// // var parts = item.name.split('.');
			// // parts.pop(); // remove the last item
			// // parts = parts.join('.').split('_');
			// var parts = item.name.split('_');
			// parts.shift(); // del the first part
			// return parts.join(' ');
		},

		getKey(pic)
		{
			if (!pic)
			{
				debugger;
			}
			return pic.mtime + pic.size + pic.name;
		},

		sort()
		{
			this.$nextTick( () =>
			{
				consola.debug('sort by ', this.sortingModel)
				if (this.sortingModel == 0)
				{
					this.onOrderByDate();
				}
				else
				{
					this.onOrderByName();
				}
			} )
		},

		onOrderByDate()
		{
			consola.debug('onOrderByDate()')

			let list = [...this.pictures].sort( (a, b) =>
			{
				let timeA = new Date(a.mtime).getTime();
				let timeB = new Date(b.mtime).getTime();

				// if (!this.sortAscending)
				if ( this.sortDescendingModel==1 )
				{
					let tmp = timeA;
					timeA = timeB;
					timeB = tmp;
				}

				if (timeA < timeB)	return -1;
				if (timeA > timeB)	return 1;
				return 0;
			});

			this.pictures = list;
		},

		onOrderByName()
		{
			consola.debug('onOrderByName()')

			let list = [...this.pictures].sort( (a, b) =>
			{
				let nameA = a.name.split('_').slice(1).join('_');
				let nameB = b.name.split('_').slice(1).join('_');

				// if (!this.sortAscending)
				if ( this.sortDescendingModel==1 )
				{
					let tmp = nameA;
					nameA = nameB;
					nameB = tmp;
				}

				return nameA.localeCompare(nameB);

				// const timeA = new Date(a.mtime).getTime();
				// const timeB = new Date(b.mtime).getTime();
				// if (timeA < timeB)	return -1;
				// if (timeA > timeB)	return 1;
				// return 0;
			});

			this.pictures = list;
		},

		// onOrderByType()
		// {
		// 	consola.debug('onOrderByType()')

		// 	let list = [...this.pictures].sort( (a, b) =>
		// 	{
		// 		const nameA = a.name.split('_').slice(1).join('_');
		// 		const nameB = a.name.split('_').slice(1).join('_');

		// 		return nameA.localeCompare(nameB);

		// 		// const timeA = new Date(a.mtime).getTime();
		// 		// const timeB = new Date(b.mtime).getTime();
		// 		// if (timeA < timeB)	return -1;
		// 		// if (timeA > timeB)	return 1;
		// 		// return 0;
		// 	});

		// 	this.pictures = list;
		// },

		onEmptyClose()
		{
			this.isOpen = false;
			this.$eventBus.$emit('media:selected', { item: null });
		},

		onSelectClose()
		{
			this.isOpen = false;
			// if ( Number.isInteger(this.selected) )
			if ( this.selectedItem )
			{
				// this.$eventBus.$emit('media:selected', { item: this.filteredPictures[this.selected] });
				this.$eventBus.$emit('media:selected', { item: this.selectedItem });
			}
			else
			{
				this.$eventBus.$emit('media:selected');
			}
		},

		onDelete()
		{
			consola.debug('onDelete')
			// if ( Number.isInteger(this.selected) )
			if ( this.selectedItem )
			{
				this.isLoading = true
				// consola.debug('onDelete: ', this.filteredPictures[this.selected].name)
				consola.debug('onDelete: ', this.selectedItem.name)
				this.$apiDelete('media', { file: this.selectedItem.name } )
					.then( resp => {
						consola.debug('delete img: ', resp)
						this.$emit('alert', {msg: 'File deleted', type: 'success' })

						this.fecthData()
							.then( (success)=>
							{
								if (success)
								{
									// this.isLoading = false
								}
							})
							.catch( error =>
							{
								consola.debug('fetch media list error: ', error );
								this.$emit('alert', {msg: 'File list error', type: 'error' })
							} )
							.finally(()=>
							{
								this.isLoading = false
							})
					})
					.catch( error =>
					{
						consola.debug('delete img error: ', error )
						this.isLoading = false;
						this.$emit('alert', {msg: 'File could not be deleted', type: 'error' })
					})
					// .finally( ()=>
					// {
					// })
			}
		},

		onReplace(event)
		{
			// if ( Number.isInteger(this.selected) )
			if ( this.selectedItem )
			{
				// consola.debug('onReplace: ', this.filteredPictures[this.selected].name)
				consola.debug('onReplace: ', this.selectedItem.name)
				this.onUpload(event, this.selectedItem.name );
			}
		},

		onUpload(event, replaceFileName = null)
		{
			this.$refs.input.click();
			this.$refs.input.onchange = _ =>
			{
				let files = Array.from( this.$refs.input.files );
				if ( files.length )
				{
					this.uploadImage( files[0], replaceFileName )
				}
				else
				{
					consola.debug('warning: no image files selected')
				}
			};
		},

		uploadImage(file, replaceFileName = null)
		{
			if (!file)	return;
			// if (!file)	Promise.reject('no file given');

			let formData = new FormData()

			formData.append('imgfile', file)
			if (replaceFileName)
				formData.append('replace', replaceFileName)

			this.isUploading = true;

			// let reader = new FileReader()
			// reader.readAsDataURL(file)
			// reader.onloadend = () => this.previewImgDataUrl = reader.result

			this.isLoading = true;
			this.uploadProgress = 0;
			this.uploadPromise = this.$apiPost('upload', formData,
			{
				onUploadProgress: (progressEvent) =>
				{
					// consola.debug(progressEvent)
					this.uploadProgress = (progressEvent.loaded / progressEvent.total) * 100;
				}
			});

			this.uploadPromise
				.then( (resp) =>
				{

					consola.debug('image upload response: ', resp.data)

					if (resp.data.error)
					{
						consola.debug(resp.data.error.message || 'Upload error');
						this.$emit('alert', {msg: resp.data.error.message || 'Upload error', type: 'error' })
					}

					if (resp.data.path)
					{
						this.$emit('alert', {msg: 'File uploaded!', type: 'success' })
						this.fecthData()
							.then( (success)=>
							{
								if (success)
								{
									// this.isLoading = false
								}
							})
							.catch( error =>
							{
								consola.debug('fetch media list error: ', error );
								this.$emit('alert', {msg: 'File list error', type: 'error' })
							} )
							.finally(()=>
							{
								this.isLoading = false
							})
					}
					this.uploadProgress = 0;
				} )
				.catch( (error) =>
				{
					consola.debug('image upload error: ', error);
					this.$emit('alert', {msg: 'File could not be uploaded', type: 'error' })
				}).
				finally( ()=>
				{
					this.isLoading = false;
					this.isUploading = false;
					this.uploadProgress = 0;
				} )

		},

	}
}
